<template>
  <portal to="modals">
    <div
      v-if="showModal"
      class="fixed inset-0 z-40 flex items-end justify-center p-10 sm:items-center"
    >
      <transition
        enter-active-class="transition-all duration-150 ease-out-quad"
        leave-active-class="transition-all duration-200 ease-in-quad"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
      >
        <div v-if="showBackdrop">
          <div
            class="absolute inset-0 bg-black opacity-25"
            @click="close"
          ></div>
        </div>
      </transition>

      <transition
        enter-active-class="transition-all duration-150 ease-out-quad"
        leave-active-class="transition-all duration-200 ease-in-quad"
        enter-class="opacity-0 scale-70"
        enter-to-class="scale-100 opacity-100"
        leave-class="scale-100 opacity-100"
        leave-to-class="opacity-0 scale-70"
        appear
        @before-leave="cardLeaving = true"
        @after-leave="cardLeaving = false"
      >
        <div
          v-if="showContent"
          :class="['w-full bg-white shadow-2xl pt-6 relative overflow-y-auto max-h-full pointer-events-auto rounded-lg mt-16', size]"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    canClose: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'max-w-xl'
    }
  },

  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false
    }
  },

  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving
    }
  },

  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          this.show()
          // Stop background scroll when modal is open
          document.body.style.setProperty('overflow', 'hidden')
        } else {
          this.close()
        }
      },
      immediate: true
    },

    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false
        this.$emit('close')
      }
    }
  },

  beforeDestroy() {
    document.body.style.removeProperty('overflow')
  },

  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close()
      }
    }
    document.addEventListener('keydown', onEscape)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape)
    })
  },

  methods: {
    show() {
      this.showModal = true
      this.showBackdrop = true
      this.showContent = true
    },

    close() {
      if (!this.canClose) {
        return
      }
      document.body.style.removeProperty('overflow')
      this.showModal = false
      this.showBackdrop = false
      this.showContent = false
      this.$emit('close')
    }
  }
}
</script>
